import React from 'react'
import "./pricing.css";
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import Content from './Content';
import { Link } from 'react-router-dom';
import Query from '../../Components/Query/Query';
import Newsletter from '../../Components/Newsletter/Newsletter';

const Pricing = () => {

  return (
    <>
      <Navbar />

      <div className='container-fluid price__banner text-center'>
        <div className='row '>
          <h2>Our Package</h2>
        </div>
      </div>

      <div className='container'>
        <div className=' pricecardrow'>
          <div className='row '>

            <div className='col-lg-4 col-md-0 col-sm-12 '></div>
    
        

            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='price__card'>
              <p>Start from</p>
            <div className='blueline'></div><br />
           
                <p><b>$ </b><span className='price__span'>249/ month</span> <br /> (4 months free with annual contract) </p>

                <button className='btn btn__cardprice'> <Link to="https://calendly.com/organizelaw/30min?month=2023-06" target="_blank">Schedule Demo</Link></button>
              </div>

          
              <div className='col-lg-4  col-sm-12 '></div>

            </div>
          </div>
        </div>
      </div>
      <Content />
      <Newsletter />
      <Query />
      <Footer />
    </>
  )
}

export default Pricing