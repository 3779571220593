import React from 'react'
import Blog1 from "../../assets/img/team/f1.png";
import Blog2 from "../../assets/img/team/f2.png";
import Blog3 from "../../assets/img/team/f3.png";
const Content = () => {
  return (
    <div>
          <section id="featured" class="featured">
      <div class="container">
      <div className='row m-3'><h2 className='text-center '><b>News </b></h2></div>
        <div class="row" data-aos="fade-left">
          <div class="col-lg-4 col-md-4 mb-4">
            <div class="member h-100" data-aos="zoom-in" data-aos-delay="100">
              <div class="pic"><img src={Blog1} class="img-fluid" alt="Blog-1" /></div>
              <div class="member-info">
                <h4>Types of Personal Injury Damages</h4>
                <a href="single-post.html">Read More <i class="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 mb-4">
            <div class="member h-100" data-aos="zoom-in" data-aos-delay="200">
              <div class="pic"><img src={Blog2} class="img-fluid" alt="Blog-2" /></div>
              <div class="member-info">
                <h4>What If My Accident Injuries Don't Show Up Right Away?</h4>
                <a href="single-post.html">Read More <i class="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 mb-4">
            <div class="member h-100" data-aos="zoom-in" data-aos-delay="300">
              <div class="pic"><img src={Blog3} class="img-fluid" alt="Blog-3" /></div>
              <div class="member-info">
                <h4>The 5 most important questions to ask your doctor</h4>
                <a href="single-post.html">Read More <i class="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 mb-4">
            <div class="member h-100" data-aos="zoom-in" data-aos-delay="300">
              <div class="pic"><img src={Blog1} class="img-fluid" alt="Blog-1" /></div>
              <div class="member-info">
                <h4>15 Steps to Take After a Crash</h4>
                <a href="single-post.html">Read More <i class="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 mb-4">
            <div class="member h-100" data-aos="zoom-in" data-aos-delay="300">
              <div class="pic"><img src={Blog2} class="img-fluid" alt="Blog-2" /></div>
              <div class="member-info">
                <h4>Do I Need a Personal Injury Attorney?</h4>
                <a href="single-post.html">Read More <i class="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 mb-4">
            <div class="member h-100" data-aos="zoom-in" data-aos-delay="300">
              <div class="pic"><img src={Blog3} class="img-fluid" alt="Blog-3" /></div>
              <div class="member-info">
                <h4>Whiplash: 5 Things You Should Know</h4>
                <a href="single-post.html">Read More <i class="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> 
    </div>
  )
}

export default Content