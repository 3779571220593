import React, { useRef, useState } from 'react'
import "./newsletter.css";
import emailjs from '@emailjs/browser';
import Modall from '../Modall/Modall';

const Newsletter = () => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
      };
    
      const closeModal = () => {
        setShowModal(false);
      };


    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”
      
        emailjs.sendForm('service_k8bafon', 'template_zrr7ezj', form.current, 'yInSdv-lTbJcSOHiU')
          .then((result) => {
            setShowModal(true)
            setTimeout(function(){
                window.location.reload();
             }, 15000);
          }, (error) => {
            alert('Somthing went wrong');
          });
      };

    return (
                  
        <div>
              {
                      <div>
                      {showModal ? (
                        <Modall closeModal={closeModal}/> // Show modal on API success
                      ) : ""}
                    </div>
                    }
            <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <h2 className="fw-semibold">Join our list for the latest updates and offers</h2>
                        </div>
                        <div className="col-lg-6">
                            <form ref={form} onSubmit={sendEmail}>
                                <input type="email" name="email" placeholder="Enter Your Email" required/>
                                    <input type="submit" value="Subscribe" />
                                    </form>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            )
}

            export default Newsletter