import React from 'react'

const Testimonial = () => {
  return (
    <div>
    <section id="testimonials" className="testimonials ">
      <div className="container">
      <div className='row '>
      <div className='text-center testimonial__heading'>
                <h2> <b>Testimonials </b></h2>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center text-center mb-3'>
            <figure className="snip1157">
                        <blockquote>AccidentMATE helped me connect to the right doctors and attorneys during a traumatic time.
                           
                        </blockquote>
                       
                        <div className="author">
                            <h5>Saul Goodman</h5>
                        </div>
                    </figure>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center text-center mb-3'>
                    <figure className="snip1157">
                        <blockquote>After going loops around to find the right way to approach my injury case, AccidentMATE became my go-to website for doing it the right way.
                           
                        </blockquote>
                       
                        <div className="author">
                            <h5>Sara Wilsson</h5>
                        </div>
                    </figure>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center text-center mb-3'>
                    <figure className="snip1157">
                        <blockquote>The last few months were not easy on my family, but you guys stepped in and helped me immensely to reduce the mental stress of finding the proper help. 
                           
                        </blockquote>
                     
                        <div className="author">
                            <h5>Jena Karlis</h5>
                        </div>
                    </figure>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center text-center mb-3'>
                    <figure className="snip1157">
                        <blockquote>I never thought seeking help from doctors and attorneys could get this simple. I would recommend you guys in a blink.
                            
                        </blockquote>
                     
                        <div className="author">
                            <h5>Jane Smith</h5>
                        </div>
                    </figure>
                </div>

               

            
            </div>
        </div>
</section>
</div>
  )
}

export default Testimonial
