import React from 'react'
import "./services.css";

const Banner = () => {
  return (
    <div className=''>
    <div className='container services__banner'>
            <h1> <b>Services </b></h1>
    </div>
    </div>
  )
}

export default Banner