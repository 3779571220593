import React from 'react'
import "./news.css";
import Navbar from '../../Components/Navbar/Navbar';
import Query from '../../Components/Query/Query';
import Newsletter from '../../Components/Newsletter/Newsletter';
import Footer from '../../Components/Footer/Footer';

import Content from './Content';

const News = () => {
  return (
    <div>
            <Navbar />
           
            <Content />
            <Query />
            <Newsletter />
            <Footer />
    </div>
  )
}

export default News