import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Newsletter from "../../Components/Newsletter/Newsletter"
import Navbar from "../../Components/Navbar/Navbar"

import Content from './Content'
import "./securitypolicyhero.css"
import Query from '../../Components/Query/Query'
const Securitypolicy = () => {
    return (
        <div>
            <Navbar />
            
            <Content />
            <Query />
            <Newsletter />
            <Footer />
        </div>
    )
}

export default Securitypolicy