import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Whatweoffer from './Whatweoffer'
import "./services.css"
import Query from '../../Components/Query/Query'
import Banner from './Banner'
import Whychooseusnew from './Whychooseusnew'
const Services = () => {
  return (
    <div>
        <Navbar />
        <Banner />
     
        <Whatweoffer />
        <Whychooseusnew />
    
        <Query />
    
        <Footer />
    </div>
  )
}

export default Services