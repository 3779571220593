import React from 'react'
import { Icon } from '@iconify/react';
import Typewriter from "typewriter-effect";
import TypeWriterEffect from 'react-typewriter-effect';
const Hero = () => {




    return (
        <div className='main__hero'>
            <div className='container '>
                <div className='row pt-0 pb-5 '>
                    <div className='col-lg-6 col-md-6 col-sm-6 hero__div' >
                        <h1><Typewriter options={{ strings: ["Connect . Collaborate . Grow"], autoStart: true, loop: true }} /> </h1>

                        <p >Secure, HIPAA Compliant, Customizable</p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6'>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
