import React, { useEffect } from 'react'
import "./query.css";
import Phone_icon from "../../assets/img/button1.png"
import { Icon } from '@iconify/react';
import AvatarImage from "../../assets/images/man.png"
const Query = () => {
  
  function myFunction() {

    document.getElementById("deployform").classList.add('open');
  }

  function myFunction1() {

    document.getElementById("deployform").classList.remove('open');
  }

  return (
    <div>
      <div>

      </div>
      <section id="query">
        <div id="deployform" className="deployform">
          <img src={Phone_icon} alt="Phone_icon" className="icon" onClick={myFunction} />
            

            <i className="fa fa-times cancel" onClick={myFunction1} aria-hidden="true"></i>
            
            <form className="">
            <span> <img src={AvatarImage} />Hi, There</span>
              <input className='' type="text" placeholder="Name" required />
              <input className='' type="email" placeholder="Email" required />
              <input className='' type="text" placeholder="Phone" required />
              <textarea rows="2" placeholder="Your message" required></textarea>
              <button className='btn btn-primary'><a href="contact.html" >Talk to us today</a></button>
            </form>
           
        </div>
     
      </section>
      

    </div>
  )
}

export default Query
