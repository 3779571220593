import React from 'react'
import ReactModal from 'react-modal';
import "../Modall/modall.css";
import logo from "../../assets/images/logos/Logofinal.png"

const Modall = ({ closeModal } ) => {
  return (
    
       <ReactModal  isOpen={true} style={{overlay: {height: '330px' , width:'500px',top:"15%", left:"25%" ,backgroundColor:"#008bd0", border:"none"}}} onRequestClose={closeModal}>
      <div className='modal_center'>
        <img className='img-fluid modall_img' src={logo}></img>
      <h3 className='text-center'>Thank you for contacting us!<br /> Our team will contact you within 24 hours.</h3>

      <button className='btn btn-primary text-center mt-3' onClick={closeModal}>Close</button>
      </div>
    </ReactModal>
   
  )
}

export default Modall
