import React from 'react'
import Experience from "../../assets/img/inner/icon7.png";
import Diamond_Icon from "../../assets/img/inner/diamond.png";
import SupportBlue from "../../assets/img/inner/supportblue.png";
import Value from "../../assets/img/inner/valuered.png";

const Whychooseusnew = () => {
  return (
    <div>
      <div className='container'>

        <div className="row text-center mt-5">
          <h2 className="fw-semibold fs-3 c1">Why Choose Us</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis.</p>
        </div>


        <div className='row mt-3 mb-5'>
          <div className='col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center flex-column text-center'>
            <img src={Experience} className="img-fluid s-img w-25" alt="Experience" />
            <h5 className="fw-semibold">Experience</h5>
            <p className="description">Lorem ipsum dolor sit amet, consectetur adipisi cing elit, sed do eiusmod tempor incididunt ut abore et dolore magna</p>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center flex-column text-center'>
            <img src={Diamond_Icon} className="img-fluid s-img w-25" alt="Diamond" />
            <h5 className="fw-semibold">Prestigious</h5>
            <p className="description">Lorem ipsum dolor sit amet, consectetur adipisi cing elit, sed do eiusmod tempor incididunt ut abore et dolore magna</p>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center flex-column text-center'>
            <img src={Value} className="img-fluid s-img w-25" alt="Value" />
            <h5 className="fw-semibold">Value for Money</h5>
            <p className="description">Lorem ipsum dolor sit amet, consectetur adipisi cing elit, sed do eiusmod tempor incididunt ut abore et dolore magna</p>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center flex-column text-center'>
            <img src={SupportBlue} className="img-fluid s-img w-25" alt="Support" />
            <h5 className="fw-semibold">24/7 Support</h5>
            <p className="description">Lorem ipsum dolor sit amet, consectetur adipisi cing elit, sed do eiusmod tempor incididunt ut abore et dolore magna</p>
          </div>


        </div>
      </div>
    </div>
  )
}

export default Whychooseusnew