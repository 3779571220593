import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Newsletter from '../../Components/Newsletter/Newsletter'
import Footer from '../../Components/Footer/Footer'

import Content from './Content'
import Query from '../../Components/Query/Query'

const Privacypolicy = () => {
  return (
    <div>
        <Navbar />
       
        <Content />
        <Query />
        <Newsletter />
        <Footer />
    </div>
  )
}

export default Privacypolicy