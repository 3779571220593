import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../../Components/Navbar/Navbar'
import "./platform.css";
import Footer from '../../Components/Footer/Footer';
import Query from '../../Components/Query/Query';
import image from '../../assets/imagegirl.png';
import logo from '../../assets/img/plateform/Logo symbol color 2.png';
import box from '../../assets/img/plateform/octangle-shape.png';
import Layer1 from "../../assets/images/Icon/layer-2.png";
import Layer2 from "../../assets/images/Icon/Layer-1.png";
import Layer3 from "../../assets/images/Icon/layer-3.png";
import Layer4 from "../../assets/images/Icon/layer-4.png";
import Layer5 from "../../assets/images/Icon/layer-5.png";
import Layer6 from "../../assets/images/Icon/layer-6.png";
import { Icon } from '@iconify/react';
import Newsletter from '../../Components/Newsletter/Newsletter';
const Platform = () => {
  return (
    <>
      <Navbar />
      <section id="pricing-page">
        <div className="container-xxl">
          <div className="row">
            <div className="col-lg-6 col-md-6 left-section">
              <div className="left-section-content">
                <p><b className="organizemed">OrganizeLAW</b> is a pioneering solution built on AccidentMATE platform that strives to bring efficiency, convenience, and collaboration to the medico-legal workflow. <br /><br />Our platform is designed to streamline the process of handling accident cases, making it hassle-free for all parties involved. Robust, scalable, and adaptable practice management solution to make the medico-legal process flow frictionless.<br /><br /> Speed up your intake from weeks to hours with our customized  Integrated Client or Patient mobile app. We leverage state-of-the-art AI/ML technology, data analysis, and collaboration tools to ensure our platform is user-friendly, efficient, and effective.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 right-section">
              <img src={logo} className="logo-fade img-fluid" />
            </div>
          </div>
        </div>
      </section>


      <div className="container-xxl mt-5 mobile-top-part">
        <div className="row">

          <div className="col-lg-6 col-md-6">
            <div className="mobile-image">
              <img src={image} className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="mobile-content">
              <h3>Can’t find what you need? Talk to <br />our sales team. </h3>
              <p className="pt-2">Our customizable platform is built around your needs. Talk to our sales <br />team to learn about features and customization options.</p>
              < form className="nav-item  sectionp mt-3 mt-sm-5" role="button" >
                <Link to="/contact" className="btn" ><span className='section_span'>Sign up for trial</span></Link>
              </form >
            </div>
          </div>
        </div>
      </div>

      <section id="our-value">
        <p className="text-center our-value-para">A state-of-the-art solution to enable multi-channel referrals and reduce or accelerate manual tasks</p>
        <div className="container">
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >

                  <img className='section2__icon1' src={Layer1}></img>
                 
                </div>
                <h4> Expand your Medical and Legal Network</h4>
                <p>Discover and build your Med-Legal network with specialized medical providers and experienced attorneys.</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >

                  <img className='section2__icon1' src={Layer2}></img>
                 
                </div>
                <h4>Multi-Channel Client Referrals</h4>
                <p>Source referrals from the AccidentMATE website, chiropractors, doctors, other attorneys and satisfied clients.. </p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >

                  <img className='section2__icon1' src={Layer3}></img>
                 
                </div>
                <h4>Instant/up-to-date access to Medical Records</h4>
                <p>Get medical records delivered to your case file automatically through seamless OrganizeMED EHR Integration.</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >

                  <img className='section2__icon1' src={Layer4}></img>
                 
                </div>
                <h4>Increase Case Value through value drivers captured in medical documentation</h4>
                <p>Level up to insurance software that searches for appropriate value drivers in medical documentation.</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >

                  <img className='section2__icon1' src={Layer5}></img>
 
                </div>
                <h4> Dedicated Client App Customized to your Practice</h4>
                <p>Gather all accident information, and get office documents & retainer signed instantly, all through one client app customized for your law practice. </p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 '>
              <div className='section2__item'>
                <div className='d-flex justify-content-between pb-5' >
                  <img className='section2__icon1' src={Layer6}></img>
                </div>
                <h4> Secure, Customizable, Compliant</h4>
                <p>Google Cloud hosted, secure, HIPAA-complaint platform with Aes-256-bit encryption and high data security. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <div className="space-section mt-3 mt-sm-5">
        <div className="container">
          <div className="row">
            <h3>Start with AccidentMATE</h3>
            <p> A platform built for medico-legal ecosystem</p>
          </div>
        </div>
      </div>
      <div className="container-xxl plateform-network mt-5">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div className="plateform-network2 pt-0 pt-sm-5">
              <h4>Your Network, Your Way</h4>
              <p>Bring your own contacts to the platform with an easy onboarding process, or expand your network with multi-channel referrals. Our marketplace solution will also help you to find the right outsourcing service for your needs.</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="plateform-network-image pt-5">
              <img src={box} />
              <div className="count1">
                <div className="counter-1">
                  <span>46</span>
                </div>
                <p> Access to the marketplace for outsourcing services</p>

              </div>
              <div className="count2">
                <div className="counter-1">
                  <span>96</span>
                </div>
                <p>Expand your network through new contacts</p>
              </div>
              <div className="count3">
                <div className="counter-1">
                  <span>46</span>
                </div>
                <p>Get access to updated documents in real-time</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <sectin id="hex-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-7">
              <div className="hex-section">
                <div className="shape-new1">
                  <div className="shape-content">
                    <p><b> Streamlining Process and referrals</b></p>
                    <p className="shape-text-new">AccidentMATE provides a secure platform where all the service providers can communicate easily and efficiently. This helps ensure everyone is on the same page and that critical information is preserved and timely.
                    </p>
                  </div>
                </div>
                <div className="shape-new2">
                  <div className="shape-content2">
                    <p><b>Improving collaboration</b></p>
                    <p className="shape-text-new">
                      AccidentMATE helps to foster collaboration between healthcare providers and attorneys. This can lead to better outcomes for accident victims, as everyone involved works together towards the same goal.
                    </p>
                  </div>
                </div>
              </div>
              <div className="shape-new3">
                <div className="shape-content3">
                  <p><b> Providing a single source of truth</b></p>
                  <p className="shape-text-new">
                    AccidentMATE delivers a repository for all important documents and information related to an accident. This makes it easy for everyone involved to find the information they need when they need it.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="hex-content">
                <h4>A value-driven medico- legal platform</h4>
                <p>With an easy onboarding process, short learning curve, and expanding network of providers, AccidentMATE delivers the industry-leading NETWORKED EMR.</p>
              </div>
            </div>
          </div>
        </div>
      </sectin>

      <Query />
      <Newsletter />
      <Footer />
    </>
  )
}

export default Platform