import React from 'react'
import icon6 from "../../assets/img/inner/icon6.png";
import icon5 from "../../assets/img/inner/icon5.png";
import icon4 from "../../assets/img/inner/icon4.png";
import icon3 from "../../assets/img/inner/icon3.png";
import icon2 from "../../assets/img/inner/icon2.png";
import icon1 from "../../assets/img/inner/icon1.png";

const Whatweoffer = () => {
  return (
    <div>
         <section id="main-services" className="main-services" >
      <div className="container">
        <div className="text-center" data-aos="fade-down">
    
          <h2 className="fw-semibold text-center mb-5 mt-3 ">What We Offer</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 mb-4">
            <div className="member text-center" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src={icon1} className="img-fluid" alt="image" /></div>
              <div className="member-info">
                <h4>Doctors</h4>
                <p>Lorem Ipsum is simply dummy 
                  text of the printing and typesetting 
                  industry.</p>
                <a href="doctor.html">Read More <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 mb-4">
            <div className="member text-center" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic" id="pic2"><img src={icon2} className="img-fluid" alt="image" /></div>
              <div className="member-info">
                <h4>Attorney</h4>
                <p>Lorem Ipsum is simply dummy 
                  text of the printing and typesetting 
                  industry.</p>
                <a href="#">Read More <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 mb-4">
            <div className="member text-center" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src={icon3} className="img-fluid" alt="image" /></div>
              <div className="member-info">
                <h4>Body Shop</h4>
                <p>Lorem Ipsum is simply dummy 
                  text of the printing and typesetting 
                  industry.</p>
                <a href="#">Read More <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 mb-4">
            <div className="member text-center" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic" id="pic2"><img src={icon4} className="img-fluid" alt="image" /></div>
              <div className="member-info">
                <h4>Towing</h4>
                <p>Lorem Ipsum is simply dummy 
                  text of the printing and typesetting 
                  industry.</p>
                <a href="#">Read More <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 mb-4">
            <div className="member text-center" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src={icon5} className="img-fluid" alt="image" /></div>
              <div className="member-info">
                <h4>Network</h4>
                <p>Lorem Ipsum is simply dummy 
                  text of the printing and typesetting 
                  industry.</p>
                <a href="#">Read More <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 mb-4">
            <div className="member text-center" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic" id="pic2"><img src={icon6} className="img-fluid" alt="image" /></div>
              <div className="member-info">
                <h4>Artist</h4>
                <p>Lorem Ipsum is simply dummy 
                  text of the printing and typesetting 
                  industry.</p>
                <a href="#">Read More <i className="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section> 
    </div>
  )
}

export default Whatweoffer