import React from 'react'

const Content = () => {
  return (
    <div>
        <section id="privacy" className="privacy">
      <div className="container">
        <div className='row'><h2 className='text-center '><b>Security Policy </b></h2></div>
        <div className="row" data-aos="zoom-in">
          <div className="col-lg-12 col-md-12">
            <h5 className="fw-semibold">AccidentMATE Security Policy</h5>
            <p>Effective Date: August 1st, 2021</p>

            <h5 className="fw-semibold">What is a security policy?</h5>
            <p>A security policy is frequently used in conjunction with other types of documentation such as standard operating procedures. These documents work together to help the company achieve its security goals. The policy defines the overall strategy and security stance, with the other documents helping build structure around that practice. You can think of a security policy as answering the “what” and “why,” while procedures, standards, and guidelines answer the “how.”</p>
            
            <h5 className="fw-semibold">Four reasons a security policy is important:</h5>
            <h6 className="fw-semibold"><u>1. Guides the implementation of technical controls</u></h6>
            <p>A security policy doesn’t provide specific low-level technical guidance, but it does spell out the intentions and expectations of senior management in regard to security. It’s then up to the security or IT teams to translate these intentions into specific technical actions.</p> 
            <p>For example, a policy might state that only authorized users should be granted access to proprietary company information. The specific authentication systems and access control rules used to implement this policy can change over time, but the general intent remains the same. Without a place to start from, the security or IT teams can only guess senior management’s desires. This can lead to inconsistent application of security controls across different groups and business entities.</p>
            
            <h6 className="fw-semibold"><u>2. Sets clear expectations</u></h6>
            <p>Without a security policy, each employee or user will be left to his or her own judgment in deciding what’s appropriate and what’s not. This can lead to disaster when different employees apply different standards.</p> 
            <p>Is it appropriate to use a company device for personal use? Can a manager share passwords with their direct reports for the sake of convenience? What about installing unapproved software? Without clear policies, different employees might answer these questions in different ways. A security policy should also clearly spell out how compliance is monitored and enforced.</p>
          
            <h6 className="fw-semibold"><u>3. Helps meet regulatory and compliance requirements</u></h6>
            <p>Documented security policies are a requirement of legislation like HIPAA and Sarbanes-Oxley, as well as regulations and standards like PCI-DSS, ISO 27001, and SOC2. Even when not explicitly required, a security policy is often a practical necessity in crafting a strategy to meet increasingly stringent security and data privacy requirements.</p>
          
            <h6 className="fw-semibold"><u>4. Improves organizational efficiency and helps meet business objectives</u></h6>
            <p>A good security policy can enhance an organization’s efficiency. Its policies get everyone on the same page, avoid duplication of effort, and provide consistency in monitoring and enforcing compliance. Security policies should also provide clear guidance for when policy exceptions are granted, and by whom.</p>
            <p>To achieve these benefits, in addition to being implemented and followed, the policy will also need to be aligned with the business goals and culture of the organization.</p>
          </div>
        </div>
      </div>
    </section> 
    </div>
  )
}

export default Content