import React from 'react'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
const Content = () => {
  return (
    <div>
      <div className='container'>
        <div className='row pricing__feature'>
          <p>Features</p>
        </div>
        <div className='row'>
          <table class="table ">

            <tbody>
              <tr>
                <th scope="row">Detailed Intake </th>
                <td></td>
                <td></td>
                <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
             
              </tr>


              <tr>
                <th scope="row">Office Forms Support
                </th>
                <td></td>
              <td></td>
                <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
             
              </tr>

            
              <tr>
                <th scope="row">Customized Client App with digital signing
                </th>
                <td></td>
                <td></td>
                <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
             
              </tr>

            
              <tr>
                <th scope="row">In-App Doctor Referral Network
                </th>
                <td></td>
                <td></td>
                <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
             
              </tr>

              <tr>
                <th scope="row">Medical Records Integration
                </th>
                <td></td>
                <td></td>
                <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
             
              </tr>

              
              <tr>
                <th scope="row">Medical Billing Integration
                </th>
                <td></td>
                <td></td>
                <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
             
              </tr>

              
              <tr>
                <th scope="row">Incoming Referral Integration
                </th>
                <td></td>
                <td></td>
                <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
             
              </tr>











            </tbody>
          </table>
        </div>
        <div className='row '>
          <Link to="/contact"> <button className="btn pricingbtn" style={{ width: "130px" }}>Talk to sales</button> </Link>
        </div>
        <br /><br />

        <div className='row pricing__feature'>
          <p>Add ons</p>
        </div>
        <ul className="pricing-list mt-3">
          <li><Icon icon="material-symbols:check-small-rounded" /><span>Customizations</span></li>
          <li><Icon icon="material-symbols:check-small-rounded" /><span>Storage</span></li>
          <li><Icon icon="material-symbols:check-small-rounded" /><span>Support and Backup</span></li>
          <li><Icon icon="material-symbols:check-small-rounded" /><span>Feature Development</span></li>
          <li><Icon icon="material-symbols:check-small-rounded" /><span>Onsite Training</span></li>

        </ul>
        <div className='row mb-5'>
          <Link to="/contact"> <button className="btn pricingbtn" style={{ width: "130px" }}>Talk to sales</button> </Link>
        </div>
      </div>
    </div>
  )
}

export default Content
