import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Hero from './Hero'
import "./mainpage.css";
import Section1 from './Section1';
import Howwedoit from './Howwedoit';
import Testimonial from './Testimonial';
import Footer from '../../Components/Footer/Footer';
import Doctor from './Doctor';
import Query from '../../Components/Query/Query';
import Section2 from './Section2';
import Newsletter from '../../Components/Newsletter/Newsletter';

const MainPage = () => {
  return (
    <div>
        <Navbar />
        <Hero />
        <Section2 />
        <Testimonial />
        <Doctor />
        <Query/>
        <Newsletter />
        <Footer />
    </div>
  )
}

export default MainPage
